<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.subPage"/>
      <a-card :bordered="false" v-else>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model.trim="queryParam.serial_num" allowClear placeholder="根据业务编号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="付款对象">
                  <a-select
                    showSearch
                    allowClear
                    :filter-option="filterOption"
                    placeholder="选择付款对象"
                    v-model="queryParam.customer_id"
                  >
                    <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="创建时间">
                  <a-range-picker @change="onDateChange"/>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="table-operator">
          <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
          <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
          <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!selectedRowKeys.length">删除</a-button>
        </div>

        <s-table
          ref="table"
          size="small"
          rowKey="id"
          :columns="columns"
          :data="loadData"
          :scroll="{ x: 'max-content' }"
          :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
          @dblclick="handleEdit">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <span slot="action" slot-scope="text, record">
            <template>
              <a @click="handleEdit(record)">编辑</a>
              <a-divider type="vertical" />
              <a @click="handleDelete('row', record)">删除</a>
            </template>
          </span>
        </s-table>
      </a-card>
    </keep-alive>
  </div>
</template>

<script>
import { STable } from '@/components';
import { getSupplier } from '@/api/common';
import moment from 'moment';
import { getFirstLetter } from '@/utils/util'
import {
  getItmsOfficePage,
  deleteItmsOrderInfo
} from '@/api/itms'

export default {
  name: 'ItmsOffice',
  components: {
    STable
  },
  data() {
    return {
      queryParam: {},
      selectedRowKeys: [],
      supplierOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          dataIndex: 'serial_num'
        },
        {
          title: '付款单位',
          dataIndex: 'customer_name'
        },
        {
          title: '费用合计',
          dataIndex: 'money'
        },
        {
          title: '业务费用',
          dataIndex: 'business_expenses'
        },
        {
          title: '订单开始时间',
          dataIndex: 'order_start_date'
        },
        {
          title: '订单结束时间',
          dataIndex: 'order_end_date'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: (parameter) => {
        return getItmsOfficePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      }
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === 'ItmsOffice' && this.queryParam) {
        newRoute.meta.title = '行政业务'
        this.$nextTick(_ => {
          this.$refs.table.refresh(newRoute.query.isEdit)
        });
      }
    }
  },
  mounted() {
    getSupplier({ department: 2 }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    handleCreate() {
      this.$router.push({
        name: 'ItmsOfficeCUR',
        params: { id: 'create' }
      })
    },
    handleEdit(record) {
      this.$router.push({
        name: 'ItmsOfficeCUR',
        params: record
      })
    },
    handleDelete(type, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (type === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteItmsOrderInfo({ ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true);
          });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
